@use 'variables';

@font-face {
  font-family: 'stretchit';
  src: url('https://d2bkxke45bupbc.cloudfront.net/workshop/develop/assets/fonts/stretchit/stretchit.eot');
  src:
    url('https://d2bkxke45bupbc.cloudfront.net/workshop/develop/assets/fonts/stretchit/stretchit.eot')
      format('embedded-opentype'),
    url('https://d2bkxke45bupbc.cloudfront.net/workshop/develop/assets/fonts/stretchit/stretchit.ttf') format('truetype'),
    url('https://d2bkxke45bupbc.cloudfront.net/workshop/develop/assets/fonts/stretchit/stretchit.woff') format('woff'),
    url('https://d2bkxke45bupbc.cloudfront.net/workshop/develop/assets/fonts/stretchit/stretchit.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'stretchit' !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkbox-mark {
  &:before {
    content: variables.$icon-checkbox-mark;
  }
}
.icon-date-add {
  &:before {
    content: variables.$icon-date-add;
  }
}
.icon-date-remove {
  &:before {
    content: variables.$icon-date-remove;
  }
}
.icon-date {
  &:before {
    content: variables.$icon-date;
  }
}
.icon-facebook-o {
  &:before {
    content: variables.$icon-facebook-o;
  }
}
.icon-achievement {
  &:before {
    content: variables.$icon-achievement;
  }
}
.icon-arrow-left {
  &:before {
    content: variables.$icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: variables.$icon-arrow-right;
  }
}
.icon-audio {
  &:before {
    content: variables.$icon-audio;
  }
}
.icon-caret-down {
  &:before {
    content: variables.$icon-caret-down;
  }
}
.icon-caret-up {
  &:before {
    content: variables.$icon-caret-up;
  }
}
.icon-checkmark {
  &:before {
    content: variables.$icon-checkmark;
  }
}
.icon-close {
  &:before {
    content: variables.$icon-close;
  }
}
.icon-complexity {
  &:before {
    content: variables.$icon-complexity;
  }
}
.icon-delete {
  &:before {
    content: variables.$icon-delete;
  }
}
.icon-dots {
  &:before {
    content: variables.$icon-dots;
  }
}
.icon-edit {
  &:before {
    content: variables.$icon-edit;
  }
}
.icon-facebook {
  &:before {
    content: variables.$icon-facebook;
  }
}
.icon-flame {
  &:before {
    content: variables.$icon-flame;
  }
}
.icon-flesh {
  &:before {
    content: variables.$icon-flesh;
  }
}
.icon-info {
  &:before {
    content: variables.$icon-info;
  }
}
.icon-instagram {
  &:before {
    content: variables.$icon-instagram;
  }
}
.icon-lock {
  &:before {
    content: variables.$icon-lock;
  }
}
.icon-maximize {
  &:before {
    content: variables.$icon-maximize;
  }
}
.icon-minimize {
  &:before {
    content: variables.$icon-minimize;
  }
}
.icon-pause {
  &:before {
    content: variables.$icon-pause;
  }
}
.icon-play {
  &:before {
    content: variables.$icon-play;
  }
}
.icon-star {
  &:before {
    content: variables.$icon-star;
  }
}
.icon-time {
  &:before {
    content: variables.$icon-time;
  }
}
.icon-twitter {
  &:before {
    content: variables.$icon-twitter;
  }
}
.icon-unlock {
  &:before {
    content: variables.$icon-unlock;
  }
}
