@use "../variables";

:root {
  --st-font-primary: "canada-type-gibson", sans-serif;
  --st-color-black: #231f1f;
  --st-color-full-black: #000;
  --st-color-white: #fff;
  --st-color-dark-grey: #818181;
  --st-color-shadow: #c7c7c7;
  --st-color-grey: #d4d4d4;
  --st-color-grey-2: #bdc1cc;
  --st-color-grey-3: #e7e7e7;
  --st-color-light-grey: #f9f9f9;
  --st-color-light-grey-2: #f3f4f6;
  --st-color-alert: #df0000;
  --st-color-alert-deep: #fc1717;
  --st-color-red: #cf0003;
  --st-color-yellow: #f5b325;
  --st-color-pink: #b9231f;
  --st-color-dark: #353434;
  --st-color-green: #258159;
  --st-color-dirty-white: #f2f2f2;
  --st-color-dirty-white-2: #e4e4e4;
  --st-box-shadow: 0 7px var(--box-shadow-size) rgb(189 193 204 / 10%);
  --st-box-shadow-2: 0 7px 21px rgb(144 164 183 / 22%);
  --st-box-shadow-hover: 0 7px var(--box-shadow-size) rgb(189 193 204 / 22%);
  --box-shadow-size: 21px;
  --st-color-sale: #231f1f;
}

variables.$white: #fff;
variables.$theme: "back2school";
