@use "sass:color";
@use "variables";
@use "../variables" as variables2;

@mixin fonts() {
  font-style: normal;
  font-family: var(--st-font-primary);
  margin: 0;
}

h1.st-h1,
h1 {
  font-size: 40px;
  line-height: 49px;

  @include fonts;

  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 34px;
  }

  @media (max-width: 599px) {
    font-size: 20px;
    line-height: 24px;
  }
}

h2.st-h2,
h2 {
  @include fonts;

  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  text-transform: uppercase;

  @media (max-width: 1440px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (max-width: 599px) {
    font-size: 18px;
    line-height: 22px;
  }
}

h3.st-h3 {
  @include fonts;

  font-weight: 500;
  font-size: 24px;
  line-height: 26px;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 599px) {
    font-size: 18px;
    line-height: 22px;
  }
}

h4.st-h4,
h4 {
  @include fonts;

  font-weight: 500;
  font-size: 20px;
  line-height: 22px;

  @media (max-width: 1199px) {
    font-size: 18px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (max-width: 599px) {
    font-size: 16px;
    line-height: 19px;
  }
}

p.st-p1,
p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;

  @media (max-width: 599px) {
    font-size: 14px;
    line-height: 20px;
  }
}

p.st-p2 {
  font-size: 14px;
  line-height: 20px;

  @media (max-width: 599px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.st-weight-400 {
  font-weight: 300;
}

.st-weight-900 {
  font-weight: 700;
}

.st-weight-700 {
  font-weight: 500;
}

.st-pointer {
  cursor: pointer;
}

.st-underline {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.st-separator {
  position: relative;
  font-weight: 300;
  margin: 0 auto;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 70px;
    height: 1px;
    background: var(--st-color-grey);
  }

  &::before {
    left: -10px;
    transform: translateX(-70px);
  }

  &::after {
    right: -10px;
    transform: translateX(70px);
  }
}

.st-border {
  width: 100%;
  height: 1px;
  background: var(--st-color-grey);
}

a:hover,
a:active {
  color: var(--st-color-black);
}

.inactive {
  * {
    color: var(--st-color-dark-grey);
  }
}

.loading {
  position: absolute;
  inset: 0;
  background: color.adjust(variables2.$white, $alpha: -0.2);
  z-index: 999;
}

.loading-fixed {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: color.adjust(variables2.$white, $alpha: -0.2);
  z-index: 999;
}
